window['_views']['ROOT_VIEW'] = `<h1 class="PrintMessage d-none">You are not authorized to print.</h1>
<div id="AllContent">
    <!-- <div class="position-absolute extra-div-for-height"></div> -->
    <div id="wrapper">
        <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="page-menu">

            <!-- Sidebar - Brand -->

            <div class="" style="margin-top: 0.8rem;position: relative;">
                <button style="float:left;" data-toggle="tooltip" data-placement="right" id="sidebarToggleTop"
                    class="btn btn-link rounded-circle mr-0 dotted-icon dotted-menu">
                </button>
                <h2 class="mainMenuName d-none" style="color: #fff; text-align: left; margin: 0 0 0 50px;">Menu</h2>
                <button data-toggle="tooltip" data-placement="right" id="sidebarpinmenu" class="btn menu-pin d-none"
                    data-original-title="" title="">
                    <i class="fas fa-thumbtack"></i>
                    <!-- <span class="un-pin-icon" style="display: none;"></span> -->
                </button>
            </div>

            <!-- Divider -->
            <hr class="sidebar-divider my-2">

            <!-- Nav List  -->
            <div id="nav-list" style="padding-top: 5px;"></div>
            <!-- Adding this for those navigation which are not fit in screen -->
            <div id="secondry-navs" class="position-relative" data-toggle="tooltip" title="View More Menus">
                <!--Tooltip added for view more menu -->
                <button
                    class="secondry-sidebar-collapse-button d-inline-block text-center px-3 py-2 w-100 mb-3 cursor-pointer"
                    type="button" data-toggle="collapse" data-target="#secondry-collapsable-navs-wrapper"
                    aria-expanded="false" aria-controls="#secondry-collapsable-navs-wrapper">
                    <i class="fa fa-ellipsis-h"></i>
                </button>
                <div class="secondry-nav-items collapse bg-white pt-2" id="secondry-collapsable-navs-wrapper">
                    <div id="secondry-collapsable-navs" class="custom-scrollbar">
                    </div>
                    <div class="px-2 manage-btn-of-secondary-nav">
                        <span class="text-primary cursor-pointer" id="modal-secondary-primary-navs">Manage Menu <i
                                class="fa fa-chevron-right"></i></span>
                    </div>
                </div>

            </div>
            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Sidebar Toggler (Sidebar) -->
            <!-- <div class="text-center d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div> -->

        </ul>
        <!-- End of Sidebar -->
        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">

                <!-- Topbar -->
                <div id="main-header">
                    <nav class="navbar navbar-expand navbar-light bg-white topbar">

                        <!-- Sidebar Toggle (Topbar) -->
                        <span class="d-block d-md-none mr-2" id="sidebarToggleMob">
                            <img src="/images/bar.svg" class="img-fluid" style="height: 24px;" />
                        </span>
                        <span>
                            <img src="/images/logo.svg" class="img-fluid" style="height: 42px;" />
                        </span>
                        <div class="mx-auto alert alert-primary shadow backupScheduled d-none">

                        </div>

                        <!-- Topbar Navbar -->
                        <ul class="navbar-nav ml-auto backupUl">

                            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
                            <li class="nav-item dropdown no-arrow d-sm-none">
                                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa-solid fa-magnifying-glass fa-fw"></i>
                                </a>
                                <!-- Dropdown - Messages -->
                                <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                    aria-labelledby="searchDropdown">
                                    <form class="form-inline mr-auto w-100 navbar-search">
                                        <div class="input-group focus-shadow">
                                            <input type="text" class="form-control bg-light border-0 small"
                                                placeholder="Search for..." aria-label="Search"
                                                aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-primary " type="button">
                                                    <i class="fa-solid fa-magnifying-glass fa-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </li>

                            <!-- Nav Item - Messages -->
                            <li class="nav-item dropdown no-arrow mx-1 notiBellIcon">
                                <a class="nav-link dropdown-toggle" href="#" id="notificationDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i data-toggle="tooltip" data-placement="left" title="Notification"
                                        class="fa-duotone fa-bell bell-size text-primary"></i>
                                    <!-- Counter - Messages -->
                                    <span class="badge badge-danger badge-counter"></span>
                                </a>
                                <!-- Dropdown - Messages -->
                                <div class="notification-div dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in pb-0"
                                    aria-labelledby="notificationDropdown">
                                    <!-- <h6 class="dropdown-header">
                                Notifications
                            </h6> -->

                                    <a class="dropdown-item text-center small text-gray-500 openAllNotificationLink"
                                        href="#"></a>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link task-icon d-none disabled">
                                    <i class="fa-duotone fa-clipboard-check bell-size text-primary" title="Tasks" data-toggle="tooltip"
                                        data-placement="top"></i>
                                </a>
                            </li>

                            <div class="topbar-divider d-none d-sm-block"></div>

                            <!-- Nav Item - User Information -->
                            <li class="nav-item dropdown no-arrow user-menu">
                                <a class="nav-link dropdown-toggle pr-0" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <!-- <span title="View profile and settings" data-toggle="tooltip"> -->
                                    <span title="View profile and settings" data-toggle="tooltip"
                                        class="img-profile-container">
                                        <span class="mr-2 d-none d-lg-inline-block text-gray-600 small user-name"></span>
                                        <img class="img-profile rounded-circle" src=""
                                            onerror="this.src='images/nouser.jpg';">
                                    </span>
                                    <!-- </span> -->
                                </a>
                                <!-- Dropdown - User Information -->
                                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <a class="dropdown-item btnTodaysView d-none" href="#">
                                        <i class="fa-duotone fa-calendar-day fa-sm fa-fw mr-2"></i> Today's
                                        View

                                    </a>
                                    <a class="dropdown-item btnUserProfile" href="#">
                                        <i class="fa-duotone fa-user fa-sm fa-fw mr-2"></i> Profile
                                    </a>
                                    <a class="dropdown-item btnTag" href="#">
                                        <i class="fa-duotone fa-tags fa-sm fa-fw mr-2"></i> Tags
                                    </a>
                                    <a class="dropdown-item btnMySetting" href="#">
                                        <i class="fa-duotone fa-gear fa-sm fa-fw mr-2"></i> My Settings</a>
                                    </a>
                                    <a class="dropdown-item btnStoreMenu" href="#">
                                        <i class="fas fa-duotone fa-store fa-sm fa-fw mr-2"></i> Store
                                    </a>
                                    <!--  Activity Grid -->
                                    <a class="dropdown-item btnActivities" href="#">
                                        <i class="fa-duotone fa-list-check fa-sm fa-fw mr-2"></i> Activities
                                    </a>
                                    <a class="dropdown-item link-page btnControlPanel btn-for-admin"
                                        page-name="ControlPanel" href="#">
                                        <i class="fa-duotone fa-briefcase fa-sm fa-fw mr-2"></i> Control
                                        Panel</a>
                                    <a class="dropdown-item btnHelpOpt link-page" page-name="HelpDocument" href="#">
                                        <i class="far fa-duotone fa-circle-question fa-sm fa-fw mr-2"></i>
                                        Help
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item btnLogout" href="#">
                                        <i class="fas fa-duotone fa-right-from-bracket fa-sm fa-fw mr-2"></i> Logout
                                    </a>
                                </div>
                            </li>

                        </ul>
                    </nav>
                    <div id="page-nav"
                        class="bg-white mb-2 nav nav-tabs border-bottom border-primary row ml-0 mr-0 pl-2 pr-2">

                    </div>
                </div>
                <!-- End of Topbar -->
                <!-- Begin Page Content -->
                <div class="container-fluid" id="page-body">

                </div>
                <!-- /.container-fluid -->

            </div>
            <!-- End of Content Wrapper -->

        </div>

        <div class="task-quick-view bg-light">
            <div class="task-quick-view-header">
                <div class="flex-grow-1 position-relative page-title-info">
                    <h5 class="m-0 font-weight-bold d-inline-block page-name text-primary">Tasks</h5>
                    <div class="clearfix"></div>
                    <div class="table-filter dropdown hide-edit">
                        <div class="dropdown-toggle small d-inline text-primary current-list cursor-pointer" data-toggle="dropdown"
                            aria-expanded="false" data-toggle-listtooltip="tooltip" data-placement="bottom" data-original-title="Show/Hide Lists"></div>
                        <div class="dropdown-menu animated--fade-in task-list-container" x-placement="bottom-start"
                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 16px, 0px);max-height: unset;overflow: unset; width: 270px;">
                            <div class="d-flex pl-2 pr-2">
                                <input type="text" class="form-control form-control-sm list-searcher" placeholder="Search by List">
                            </div>
                            <div class="list-dropdown" style="max-height: 250px;overflow: auto;">
                            </div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item create-new-tasklist" href="#">Create New List</a>
                            <a class="dropdown-item create-new-sub-tasklist" href="#">Create New Sub List</a>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 text-right page-top-button">
                    <button class="btn mr-n2 close-task-quick-view" data-restriction="Create" tabindex="-1">
                        <i class="fas fa-times" data-toggle="tooltip" data-placement="bottom" data-original-title="Close"></i>
                    </button>
                </div>
            </div>
            <div class="add-quick-task">
                <a class="btn btn-sm btn-outline-primary add-new-task" href="#" tabindex="-1"><i class="fas fa-plus"></i> Add a
                    Task</a>
            </div>
            <!-- Tasks list -->
            <div class="task-lists pl-3 pr-3">
                <div id="accordion">
                    <div class="card border-0" style="background-color: transparent;"></div>
                </div>
            </div>
        </div>

        <div class="modal fade diag-activity" tabindex="-1" role="dialog" aria-labelledby="ObjectModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-page-group">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
                        <!-- <button type="button" class="btn btn-dark">Save &amp; Add Primary Contact</button> -->
                        <button type="button" class="btn btn-primary btnAddObject">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-internet d-none">
            <div class="alert shadow-lg alert-danger" role="alert" style="max-width:450px; margin:0 auto;">
                <i class="fas fa-wifi"></i> Your network is unavailable. Check your data or wifi connection
                <!-- <a href="#"
                    class="btn btn-sm btn-link">Try now</a> -->
            </div>
        </div>
        <div class="fixed-bottom pl-2 pr-2 minimize-panel bg-none fixed-btn-container fixed-fotter">
        </div>
        <div id="main-loading"><img src="/images/loader.gif" /></div>

    </div>
</div>
<!-- Adding Bell DOM HTML Here -->
    <audio id="bell" class="d-none">
        <source src="/audio/done-for-you.ogg" type="audio/ogg">
        <source src="/audio/done-for-you.mp3" type="audio/mpeg">
    </audio>
<!-- Adding Bell DOM HTML Here -->`;