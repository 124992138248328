import CHECK_EMAIL from './check-email';
import FORGOT_PASSWORD from './forgot-password';
import JoinLayout from './layout';
import LOGIN, { OTP_FORM, FORGOT_USERNAME, SVG_CAPTCHA} from './login';
import SET_PASSWORD from './set-password';
import EXTERNAL_DRIVE_SHARE from './external-drive-share';
import _constantClient from '../../js/utils/constants';

const noAuth = {
    LOGIN: JoinLayout(LOGIN(_constantClient.GOOGLE_SITE_KEY)),
    SET_PASSWORD: JoinLayout(SET_PASSWORD(_constantClient.GOOGLE_SITE_KEY)),
    CHECK_EMAIL: JoinLayout(CHECK_EMAIL),
    FORGOT_PASSWORD: JoinLayout(FORGOT_PASSWORD(_constantClient.GOOGLE_SITE_KEY)),
    EXTERNAL_DRIVE_SHARE,
    FORGOT_USERNAME: JoinLayout(FORGOT_USERNAME(_constantClient.GOOGLE_SITE_KEY)),
    OTP_FORM: OTP_FORM,
    SVG_CAPTCHA: SVG_CAPTCHA,
};

window._views['noAuth'] = noAuth;